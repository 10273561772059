import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import { Box } from "@herb/ui";
import { deployUrl } from "@herb/utils";
import Container from "@herb/components/Container";
import IndutryImages from "@herb/components/IndutryImages";
import SeoImageSeriveSection from "@herb/components/SeoImageSeriveSection";
import MarketingPlan from "@herb/components/MarketingPlan";
import Fade from "react-reveal/Fade";
import IndustryImagesScroll from "@herb/components/IndustryImagesScroll";
import Button from "@herb/components/Button";
import MarketingSolutionsForHeb from "@herb/components/MarketingSolutionsForHeb";
import SEO from "@herb/components/seo";
import DispensaryHelp from "../../components/DispensaryHelp";
import FoundersCard from "../../components/FoundersCard";
import HerbPlusComments from "../../components/HerbPlusComments";
import Slider from "react-slick";
import "./index.css";
import { Helmet } from "react-helmet";
const isBrowser = typeof window !== "undefined";
const HerbPlus = props => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;

    document.body.appendChild(script);
    scriptCreate();

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  function scriptCreate() {
    if (isBrowser) {
      setTimeout(() => {
        const scriptFunction = document.createElement("script");
        scriptFunction.type = "text/javascript";
        scriptFunction.innerHTML = `
         !function(s) {
        var o = s.createElement('script'), u = s.getElementsByTagName('script')[0];
        o.src = 'https://cdn.aggle.net/oir/oir.min.js';
        o.async = !0, o.setAttribute('oirtyp', '6311ae17'), o.setAttribute('oirid', '9A6FC524');
        u.parentNode.insertBefore(o, u);
        }(document);
         !function(e) {
        var t = {  };
        (e._oirtrk = e._oirtrk || []).push(['track', 'on-site', t]);
        }(window);`;
        document.body.appendChild(scriptFunction);
      }, 500);
    }
  }
  const logos = [
    {
      id: 1,
      viewBox: "0 0 151 53",
      width: 151,
      height: 53,
      divWidth: "120.8px"
    },
    {
      id: 2,
      viewBox: "0 0 570 365",
      width: 570,
      height: 365,
      divWidth: "73.22px"
    },
    {
      id: 3,
      width: 103,
      height: 64,
      viewBox: "0 0 103 64",
      divWidth: "81.9px"
    },
    {
      id: 4,
      width: 570.19,
      height: 498.96,
      viewBox: "0 0 570.19 498.96",
      divWidth: "89.72px"
    },
    {
      id: 5,
      width: 841.89,
      height: 841.89,
      viewBox: "0 0 841.89 841.89",
      divWidth: "51.44px"
    },
    {
      id: 6,
      viewBox: "0 0 310 58",
      width: 310,
      height: 58,
      divWidth: "247.2px"
    },
    {
      id: 7,
      width: 96,
      height: 94,
      viewBox: "0 0 96 94",
      divWidth: "76px"
    },
    {
      id: 8,
      width: 190,
      height: 40,
      viewBox: "0 0 190 40",
      divWidth: "151.2px"
    },
    {
      id: 9,
      width: 66,
      height: 64,
      viewBox: "0 0 66 64",
      divWidth: "52.03px"
    },
    {
      id: 10,
      viewBox: "0 0 187 54",
      width: 187,
      height: 54,
      divWidth: "148.8px"
    },
    {
      id: 11,
      width: 109,
      height: 64,
      viewBox: "0 0 109 64",
      divWidth: "86.4px"
    },
    {
      id: 12,
      width: 92,
      height: 24,
      viewBox: "0 0 92.800003 24.4",
      divWidth: "98.4px"
    },
    {
      id: 13,
      width: 226,
      height: 40,
      viewBox: "0 0 226 40",
      divWidth: "180.31px"
    },
    {
      id: 14,
      width: 66,
      height: 64,
      viewBox: "0 0 66 64",
      divWidth: "51.7px"
    },
    {
      id: 15,
      width: 209,
      height: 40,
      viewBox: "0 0 209 40",
      divWidth: "166.7px"
    },
    {
      id: 16,
      width: 232,
      height: 56,
      viewBox: "0 0 232 56",
      divWidth: "184.8px"
    }
  ];

  const bussiness1 = [
    {
      heading: "Grow your online revenue",
      text: "Real, trackable results.",
      number: 1
    },

    {
      heading: "99% client satisfaction",
      text: "Herb works with top brands across the world.",
      number: 2
    },

    {
      heading: "94% recommend us",
      text: "Our customers think we’re the bee's knees!",
      number: 3
    }
  ];

  const bussiness2 = [
    {
      heading: "Big results",
      text: "Clients see an average 2x ROI.",
      number: 4
    },

    {
      heading: "Amazing service",
      text: "We got your back. 94% of clients recommend us.",
      number: 5
    },

    {
      heading: "#1 in cannabis-marketing-as-a-service",
      text: "The best way to market your brand online.",
      number: 6
    }
  ];

  const founderCards = [
    {
      image:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/04/11072313/Ellipse-29.png",
      name: "Dylan Savage",
      designation: "Co-Founder",
      company: "Botany Farms",
      founder: 1,
      marginRight: true
    },
    {
      image:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/04/11072314/Ellipse-29-1.png",
      name: "Dan Muscato",
      designation: "CMO",
      company: "SnowTree",
      founder: 2,
      marginRight: true
    },
    {
      image:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2023/04/11072316/Ellipse-29-2.png",
      name: "Elan Lipin",
      designation: "CEO",
      company: "Binoid",
      founder: 3,
      marginRight: false
    }
  ];
  const scrolltoMarketingForm = () => {
    document.getElementById("marketing-plan-form").scrollIntoView();
  };

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,

          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,

          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="herbPlusBg">
      <Layout>
        <>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        </>
        <Container>
          <SEO
            title={`Herb Plus`}
            url={"/herb-plus"}
            image={
              "https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/06093019/Herb-preview-Image.webp?width=500"
            }
            canonical={`${deployUrl}/herb-plus`}
          />
          {/* let’s get started */}
          <Box
            css={{
              position: "relative",
              backgroundSize: "cover",
              width: "100%",
              margin: "0 auto 124px auto",

              "@media screen and (max-width:768px)": {
                margin: "0 auto 128px auto"
              }
            }}
          >
            <Box
              css={{
                maxWidth: "1300px",
                position: "relative",
                textAlign: "center"
              }}
            >
              <Box>
                <h1 className="herbPlusHeading">Grow Your Revenue With Herb</h1>
                <p className="herbPlusMainText">
                  Work with world class marketers in 2 minutes.
                </p>
              </Box>
              <Button type={"herbPlusBtn"} onClick={scrolltoMarketingForm}>
                let’s get started
              </Button>
            </Box>
          </Box>
          {/*  Brands Section */}
          <Box
            css={{
              maxWidth: "1200px",
              margin: "0 auto 146px auto",
              "@media screen and (max-width:768px)": {
                margin: "0 auto 128px auto"
              }
            }}
          >
            <Fade bottom>
              <h2 className="ambitiousTeam">
                Trusted by the industry’s most ambitious teams
              </h2>
            </Fade>

            <Box
              css={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "1115px",
                margin: "0 auto",
                lineHeight: 5
              }}
            >
              <IndutryImages topSection={true} list={logos} />
            </Box>
          </Box>

          {/* Dispensary Help */}
          <Box
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto 146px auto",
              "@media screen and (max-width:768px)": {
                margin: "0 auto 78px auto"
              }
            }}
          >
            <DispensaryHelp
              heading={"Are you a dispensary?"}
              subHeading={
                "We can help you get more local customers & become the “go-to” for your city."
              }
              buttonText={"Grow Your Dispensary Here"}
              buttonLink={"/dispensary-plus"}
              herbPlus={true}
            />
          </Box>
        </Container>

        {/* Founders Section */}
        <Box
          css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "0 auto 96px auto",
            "@media screen and (max-width:1280px)": {
              justifyContent: "space-evenly"
            },
            "@media screen and (max-width:1024px)": {
              margin: "0"
            },
            "@media screen and (max-width:768px)": {
              flexDirection: "column",
              margin: "0 auto 73px auto"
            },
            "@media screen and (max-width:480px)": {
              display: "none"
            }
          }}
        >
          {founderCards.map((card, index) => {
            return (
              <FoundersCard
                key={index}
                image={card.image}
                name={card.name}
                designation={card.designation}
                company={card.company}
                description={card.description}
                highlightedText={card.highlightedText}
                founder={card.founder}
                marginRight={card.marginRight}
              />
            );
          })}
        </Box>
        <div className="founderCardMain">
          <Container>
            <Slider className="founderSlider" {...settings}>
              {founderCards.map((card, index) => {
                return (
                  <FoundersCard
                    key={index}
                    image={card.image}
                    name={card.name}
                    designation={card.designation}
                    company={card.company}
                    description={card.description}
                    highlightedText={card.highlightedText}
                    founder={card.founder}
                    marginRight={card.marginRight}
                  />
                );
              })}
            </Slider>
          </Container>
        </div>
        <Container>
          {/* Image service Sections */}
          <Box
            css={{
              margin: "0 auto 146px auto",
              "@media screen and (max-width:768px)": {
                margin: "0 auto 80px auto"
              }
            }}
          >
            <SeoImageSeriveSection
              heading="#1 in Cannabis Marketing"
              bussiness={bussiness1}
              image1="Frame61.png"
              image1Styles={{
                width: "87%",
                height: "580px",
                objectFit: "contain",
                "@media only screen and (max-device-width: 1024px) and (min-device-width: 770px)": {
                  width: "80%"
                },
                "@media screen and (max-width:769px)": {
                  width: "100%",
                  height: "50%"
                }
              }}
            />
          </Box>

          {/* 2300% Grow section */}
          <Box
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto 146px auto",
              "@media screen and (max-width:768px)": {
                margin: "0 auto 80px auto"
              }
            }}
          >
            <p className="revneuText">
              “WE SAW{" "}
              <span
                css={{
                  background:
                    "radial-gradient(100% 100% at 50% 50%, #F9CCCC 0%, rgba(255, 255, 255, 0) 100%);"
                }}
              >
                2,300% YEAR-OVER-YEAR REVENUE
              </span>{" "}
              GROWTH FROM HERB’S SEO, EMAIL, AND COMMUNITY TACTICS.{" "}
              <span
                css={{
                  color: "#3041FF"
                }}
              >
                I CAN’T SAY ENOUGH GOOD THINGS ABOUT THEIR CUSTOMER SERVICE
              </span>{" "}
              AND HUNGER TO ACHIEVE RESULTS.”
            </p>
            <p
              css={{
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Source Code Pro",
                paddingTop: "15px"
              }}
            >
              Dylan, Botany Farms
            </p>
          </Box>
          {/* The Best Way To Grow Your Online Revenue */}
          <Box
            css={{
              textAlign: "center",
              margin: "0 auto 146px auto",
              "@media screen and (max-width:768px)": {
                margin: "0 auto 73px auto"
              }
            }}
          >
            <Fade bottom>
              <h2 className="marketingSolutions">
                The Best Way To Grow Your Online Revenue
              </h2>
            </Fade>
            <Fade bottom>
              <div>
                <MarketingSolutionsForHeb />
              </div>
            </Fade>
            <Button type={"herbPlusBtn"} onClick={scrolltoMarketingForm}>
              Let's Get Started
            </Button>
          </Box>
        </Container>

        {/*  Herb Comments */}
        <Box
          css={{
            margin: "146px 0 0 0",
            "@media screen and (max-width:768px)": {
              margin: "75px 0 0 0"
            }
          }}
        >
          <HerbPlusComments />
        </Box>
        <div className="indusImgScroll">
          <IndustryImagesScroll list={logos} direction="right" />
        </div>

        <div
          css={{
            "@media screen and (max-width:1024px) and (min-width:769px)": {
              padding: "0 64px"
            }
          }}
        >
          {" "}
          <SeoImageSeriveSection
            heading="Pretty Freaking Powerful Marketing"
            bussiness={bussiness2}
            image1="Boutique Product Page.png"
            image2="Frame 255.png"
            reverse={true}
            text="Our cannabis marketing solution taps into the largest and most
            engaged community of consumers globally to deliver better customer
            experiences."
            image1Styles={{
              position: "relative",
              width: "90%",
              height: "490px",
              objectFit: "contain",
              "@media only screen and (max-device-width: 1235px) and (min-device-width: 1025px)": {
                width: "400px"
              },
              "@media only screen and (max-device-width: 1024px) and (min-device-width: 770px)": {
                width: "80%"
              },
              "@media screen and (max-width:768px)": {
                width: "80%",
                height: "100%"
              }
            }}
            image2Styles={{
              position: "absolute",
              top: "60%",
              right: "0",
              width: "58%",
              height: "490px",
              "@media only screen and (max-device-width: 1024px) and (min-device-width: 770px)": {
                width: "50%"
              },
              "@media screen and (max-width:769px)": {
                width: "50%",
                right: "4%",
                height: "auto"
              }
            }}
          />
        </div>

        <div
          className="lowerBackgroundColor"
          css={{
            display: "flex",
            paddingTop: "150px",
            "@media screen and (max-width:769px)": {
              paddingTop: "0px"
            }
          }}
        >
          <MarketingPlan />
        </div>
      </Layout>
    </div>
  );
};

export default HerbPlus;
